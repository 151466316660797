import { trackCart, trackPurchase, trackAddPaymentInfo } from '@/application/utils/tracking-scripts'

export const initPaddle = (callback) => {
  const scriptElement = document.createElement('script')
  scriptElement.setAttribute('src', 'https://cdn.paddle.com/paddle/paddle.js')
  document.body.appendChild(scriptElement)
  scriptElement.addEventListener('load', () => {
    if (process.env.VUE_APP_PADDLE_SANDBOX) {
      window.Paddle.Environment.set('sandbox')
    }
    window.Paddle.Setup({
      vendor: Number(process.env.VUE_APP_PADDLE_VENDOR_ID),
      eventCallback: (data) => {
        if (!data.eventData.checkout.completed) {
          return
        }

        if (data.event === 'Checkout.Loaded') {
          trackCart(data.eventData)
        } else if (data.event === 'Checkout.PaymentComplete') {
          trackPurchase(data.eventData)
        } else if (data.event === 'Checkout.Complete') {
          trackAddPaymentInfo(data.eventData)
        }
        callback(data)
      },
    })
  })
}
