<template>
  <div class="table-responsive adaptive-table" v-if="list.length > 0">
    <table class="table table-striped table-hover adaptive-table" style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in dataList" :key="row.id">
          <td :data-label="$t('forms.billing.rows.price')">
            {{ $t('forms.billing.rows.price') }}: {{ row.localized_price }}<br />
            {{ $t('forms.billing.rows.quantity') }}: {{ row.quantity }}<br />
            {{ $t('forms.billing.rows.status') }}: {{getLocalizedOrValue($i18n, `forms.billing.payment_statuses.${row.status}`, row.status)}}
          </td>
          <td :data-label="$t('forms.billing.rows.items')">
            <p v-if="row.message_package">
              {{ $t(`forms.billing.rows.types.${row.message_package.type}`) }}: {{ row.message_package.messages }}<br />
              {{ $t('forms.billing.rows.term') }}: {{ $t(`forms.buyPackages.terms.${row.message_package.term}`) }}<br />
            </p>
            <div v-if="row.sender_names">
              <p v-for="senderName, index in row.sender_names" :key="index">
                <button class="btn btn-primary btn-sm" @click.prevent="goToSenderNameDetails(senderName)">{{ $t('details') }}</button>&nbsp;
                {{ senderName.name }}
              </p>
            </div>
          </td>
          <td :data-label="$t('forms.billing.rows.paymentMethod')">
            <div v-if="row.payment_info">
              {{ $t('forms.billing.rows.paymentMethod') }}: {{getLocalizedOrValue($i18n, `paymentMethod.${row.payment_info.payment_method}`, row.payment_info.payment_method)}}
              <br />
              <span v-if="row.payment_info.last_four_digits">
                {{ $t('forms.billing.rows.lastFourDigits') }}: {{ row.payment_info.last_four_digits }}
                <br />
              </span>
              <span v-if="row.payment_info.expiry_date">
                {{ $t('forms.billing.rows.expiryDate') }}: {{ row.payment_info.expiry_date }}
              </span>
              <div class="mt-2" v-if="row.update_url">
                <button class="btn btn-primary btn-sm" @click.prevent="editPaymentMethodHandler(row.update_url)">{{ $t('forms.billing.rows.editPaymentMethod') }}</button>
              </div>
            </div>
          </td>
          <td :data-label="$t('forms.billing.rows.nextBillDate')">
            <p v-if="row.next_bill_date">{{ $t('forms.billing.rows.nextBillDate') }}: {{ humanDate(row.next_bill_date, $i18n.locale) }}</p>
            <p v-if="row.cancellation_effective_date">
              {{ $t('forms.billing.rows.cancellationEffectiveDate') }}: {{ humanDate(row.cancellation_effective_date, $i18n.locale) }}
            </p>
            <div v-if="row.cancel_url">
              <button class="btn btn-sm btn-primary" @click.prevent="cancelPurchaseHandler(row.cancel_url)">{{ $t('cancel') }}</button>
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useHumanDate } from '@/application/composables/humanDate.js'
import { onMounted, ref } from 'vue'
import { getLocalizedOrValue } from '@/application/utils/localization'
import { useRouter } from 'vue-router'
import { useModal } from '@/application/composables/modal'
import { useI18n } from 'vue-i18n'
import { initPaddle } from '@/application/utils/paddle'

export default {
  name: 'ActivePurchasesTable',
  props: {
    list: {
      type: Array,
      required: true,
    },
    license: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()
    const i18n = useI18n()
    const { openModal } = useModal()
    const { humanDate, humanDateTime } = useHumanDate()

    const dataList = ref(props.list)
    const fields = [
      'forms.billing.rows.price',
      'forms.billing.rows.items',
      'forms.billing.rows.paymentMethod',
      'forms.billing.rows.nextBillDate',
    ]

    onMounted(() => {
      window.$(function() {
        window.$('[data-toggle="tooltip"]').tooltip()
      })
      initPaddle(() => {
        window.Paddle.Setup()
      })
    })

    return {
      humanDate,
      humanDateTime,
      fields,
      dataList,
      getLocalizedOrValue,
      editPaymentMethodHandler: (url) => {
        window.Paddle.Checkout.open({
          override: url,
          disableLogout: true,
          successCallback: function() {
            openModal(i18n.t('success'), i18n.t('successEditPaymentMethod'), () => {
              window.location.reload()
            })
          },
        })
      },
      cancelPurchaseHandler: (url) => {
        window.Paddle.Checkout.open({
          override: url,
          disableLogout: true,
          successCallback: function() {
            openModal(i18n.t('success'), i18n.t('successCancelPurchase'), () => {
              window.location.reload()
            })
          },
        })
      },
      goToSenderNameDetails: (senderName) => {
        router.push({ name: 'detail-sender-name', params: { id: senderName.id } })
      },
    }
  },
}
</script>
